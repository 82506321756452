import {theme as chakraTheme, extendTheme} from '@chakra-ui/react';
import {MultiSelectTheme} from 'chakra-multiselect';
// eslint-disable-next-line import/no-internal-modules
import colors from 'tailwindcss/colors';

const fontFamily =
  'Roboto,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

export const theme = extendTheme({
  colors: {
    blue: colors.blue,
    gray: colors.slate,
    green: colors.green,
    primary: {
      ...colors.blue,
      500: colors.blue['700'],
    },
    red: colors.red,
  },
  components: {
    Alert: {
      variants: {
        subtle: {
          container: {
            bg: 'red.50',
            borderColor: 'red.200',
            borderWidth: 1,
            color: 'red.500',
            rounded: 'lg',
          },
          description: {},
          icon: {},
          title: {},
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: chakraTheme.fontWeights.medium,
      },
    },
    Link: {
      defaultProps: {
        variant: 'primary',
      },
      variants: {
        black: ({colorScheme = 'gray'}) => ({
          _hover: {
            color: `primary.700`,
          },
          color: `${colorScheme}.900`,
        }),
        primary: ({colorScheme = 'primary'}) => ({
          _hover: {
            color: `${colorScheme}.700`,
          },
          color: `${colorScheme}.700`,
        }),
      },
    },
    MultiSelect: MultiSelectTheme,
    Tabs: {
      variants: {
        'soft-semirounded': {
          ...chakraTheme.components.Tabs.variants?.['soft-rounded'],
          tab: {
            _selected: {
              bg: 'gray.100',
              textColor: 'gray.900',
            },
            // ...chakraTheme.components.Tabs.variants['soft-rounded'].tab,
            borderRadius: 'md',

            fontSize: 'md',
            fontWeight: 'medium',
            mr: 2,
            textColor: 'gray.600',
          },
          tablist: {},
        },
      },
    },
  },
  //   Tabs: {
  //     variants: {
  //       'soft-semirounded': {
  //         ...chakraTheme.components.Tabs.variants['soft-rounded'],
  //         tab: {
  //           ...chakraTheme.components.Tabs.variants['soft-rounded'].tab,
  //           borderRadius: 'md',
  //         }
  //       }
  //     }
  //   }
  // }
  fonts: {
    body: fontFamily,
    heading: fontFamily,
  },
});
