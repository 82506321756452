import {ChakraProvider} from '@chakra-ui/react';
import {withEmotionCache} from '@emotion/react';
import type {LinksFunction, MetaFunction} from '@remix-run/node'; // Depends on the runtime you choose
import {Links, Meta, Outlet, Scripts, ScrollRestoration, useLocation} from '@remix-run/react';
import type React from 'react';
import {useContext, useEffect} from 'react';
import {AdminLayout} from '#/app/components/AdminLayout';
import {DefaultLayout} from '#/app/components/DefaultLayout';
import {ClientStyleContext, ServerStyleContext} from '#/app/helpers/Emotion/context';
import {theme} from '#/app/theme';
import '#/app/styles.css';

export const meta: MetaFunction = () => {
  return [
    {charSet: 'utf-8'},
    {title: 'KC Kosher Coop - Administration'},
    {content: 'width=device-width, initial-scale=1', name: 'viewport'},
  ];
};

export const links: LinksFunction = () => {
  return [
    {href: 'https://fonts.googleapis.com', rel: 'preconnect'},
    {href: 'https://fonts.gstatic.com', rel: 'preconnect'},
    {
      href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap',
      rel: 'stylesheet',
    },
  ];
};

interface DocumentProps {
  children: React.ReactNode;
}

const Document = withEmotionCache(({children}: DocumentProps, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {pathname} = useLocation();
  const Layout = pathname.indexOf('/admin') === 0 ? AdminLayout : DefaultLayout;

  return (
    <html className="" lang="en">
      <head>
        <Meta />
        <Links />
        {serverStyleData?.map(({css, ids, key}) => (
          <style dangerouslySetInnerHTML={{__html: css}} data-emotion={`${key} ${ids.join(' ')}`} key={key} />
        ))}
      </head>
      <body>
        <Layout>{children}</Layout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
});

export default function App() {
  return (
    <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>
  );
}
